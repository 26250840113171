import React from 'react';
import xss from 'xss';

const PrintHtmlText = ({ text }) => {
  const cleanText = xss(text);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanText }} />
  );
}

export default PrintHtmlText;